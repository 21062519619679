import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import Img from '../Image';
import Explotion from '../Explotion';

import {
  Option,
  OptionText,
  OptionImage,
  OptionChecked,
  OptionContent,
  OptionContainer,
  OptionPercentage,
} from './SingleOptionStyle';

const SingleOption = ({
  type,
  isWinner,
  image,
  winnerImage,
  width,
  label,
  height,
  selected,
  iconCheck,
  labelSize,
  unselected,
  shouldAnimateWinner,
  percentage,
  scaleAnimation,
  scaleContent,
  scaleDuration,
  reduceContent,
}) => {
  const typeOption = type === 'option';
  const typeResult = type === 'result';

  const renderPercentage = () => (
    <OptionPercentage>
      <Text
        variant={isWinner ? 'sofiaProBold' : 'sofiaProLight'}
        text={`${percentage}%`}
        size={labelSize}
      />
    </OptionPercentage>
  );

  return (
    <Option width={width} height={height}>
      <OptionContainer selected={typeOption && selected}>
        <OptionContent
          selected={typeOption && selected}
          unselected={typeOption && unselected}
          scaleImage={typeOption && scaleContent}
          reduceContent={typeOption && reduceContent}
        >
          {typeResult && renderPercentage()}
          <OptionImage
            selected={typeOption && selected}
            scaleAnimation={typeOption && scaleAnimation}
            scaleDuration={scaleDuration}
          >
            <OptionChecked selected={typeOption && selected}>
              <Img src={iconCheck} alt={label} width="100%" />
            </OptionChecked>
            <Img
              src={isWinner ? winnerImage : image}
              alt={label}
              width="100%"
            />
            {isWinner && shouldAnimateWinner && <Explotion />}
          </OptionImage>
          <OptionText>
            <Text
              variant={isWinner ? 'sofiaProBold' : 'sofiaProLight'}
              text={label}
              size={labelSize}
            />
          </OptionText>
        </OptionContent>
      </OptionContainer>
    </Option>
  );
};

SingleOption.defaultProps = {
  labelSize: '1.5rem',
  type: 'option',
  selected: false,
  unselected: false,
  isWinner: false,
  shouldAnimateWinner: false,
  percentage: '',
  scaleDuration: 0.3,
};

SingleOption.propTypes = {
  type: PropTypes.string,
  isWinner: PropTypes.bool,
  image: PropTypes.string.isRequired,
  winnerImage: PropTypes.string.isRequired,
  iconCheck: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.string,
  labelSize: PropTypes.string,
  selected: PropTypes.bool,
  unselected: PropTypes.bool,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  scaleAnimation: PropTypes.number.isRequired,
  shouldAnimateWinner: PropTypes.bool,
  scaleContent: PropTypes.number.isRequired,
  scaleDuration: PropTypes.number,
  reduceContent: PropTypes.number.isRequired,
};

export default SingleOption;
