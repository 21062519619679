import styled from 'styled-components';

const base = () => {
  return `
    font-size: 14px;
  `;
};

const tiempos = () =>
  `
  font-family: tiempos;
  font-weight: bold;
`;

const sofiaProLight = () =>
  `
 font-family: Sofia-Pro-Light;
`;

const sofiaProBold = () =>
  `
  font-family: Sofia-Pro-Bold;
`;

const sofiaProBlack = () =>
  `
  font-family: Sofia-Pro-Black;
`;

const sofiaProRegular = () =>
  `
  font-family: Sofia-Pro-Regular;
`;

const variants = {
  default: base,
  tiempos,
  sofiaProLight,
  sofiaProRegular,
  sofiaProBold,
  sofiaProBlack,
};

export const Text = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.color || 'white'};
  font-size: ${(props) => props.size || '16px'};
  text-transform: ${(props) => props.uppercase || 'none'};
  ${(props) => variants[props.variant]};
`;

const styles = {
  Text,
};

export default styles;
