import React from 'react';
import PropTypes from 'prop-types';

// Components
import Img from '../Image';
import Text from '../Text';
import ButtonComponent from '../Button';

// Assets
import Warning from '../../assets/icon-warning.svg';

import {
  Container,
  Background,
  Wrapper,
  ContentImage,
  ContentTitle,
  ContentButton,
} from './ModalStyle';

const Modal = ({
  widthIconAlert,
  title,
  titleColor,
  titleSize,
  buttonText,
  buttonTextSize,
  onPress,
  textAlert,
  textAlertSize,
  textAlertColor,
  widthModal,
  heightModal,
  minHeightModal,
  minWidthModal,
  isOpen,
  titleMaxWidth,
  zIndex,
}) => {
  return (
    <Container zIndex={zIndex} isOpen={isOpen}>
      <Background />
      <Wrapper
        width={widthModal}
        height={heightModal}
        minHeight={minHeightModal}
        minWidth={minWidthModal}
      >
        <ContentImage>
          <Img src={Warning} alt="warning" width={widthIconAlert} />
        </ContentImage>
        <Text
          text={textAlert}
          variant="sofiaProRegular"
          color={textAlertColor}
          size={textAlertSize}
        />
        <ContentTitle titleMaxWidth={titleMaxWidth}>
          <Text
            text={title}
            variant="tiempos"
            color={titleColor}
            size={titleSize}
          />
        </ContentTitle>
        <ContentButton>
          <ButtonComponent
            disabled={false}
            type="tertiary"
            textVariant="sofiaProRegular"
            fontSize={buttonTextSize}
            onPress={() => {
              onPress();
            }}
          >
            {buttonText}
          </ButtonComponent>
        </ContentButton>
      </Wrapper>
    </Container>
  );
};

Modal.defaultProps = {
  widthIconAlert: '28px',
  title: "Sorry, we couldn't load your answer",
  titleColor: '#2f2942',
  titleSize: '24px',
  buttonText: 'Try again ',
  textAlert: 'Oops!',
  textAlertColor: '#908e8e',
  textAlertSize: '17px',
  onPress: () => {},
  widthModal: '256px',
  heightModal: '271px',
  buttonTextSize: '15px',
  minHeightModal: 'auto',
  minWidthModal: 'auto',
  titleMaxWidth: '304px',
  zIndex: 1,
};

Modal.propTypes = {
  widthIconAlert: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleSize: PropTypes.string,
  buttonText: PropTypes.string,
  textAlertColor: PropTypes.string,
  textAlert: PropTypes.string,
  textAlertSize: PropTypes.string,
  onPress: PropTypes.func,
  widthModal: PropTypes.string,
  heightModal: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  buttonTextSize: PropTypes.string,
  zIndex: PropTypes.number,
  minHeightModal: PropTypes.string,
  minWidthModal: PropTypes.string,
  titleMaxWidth: PropTypes.string,
};

export default Modal;
