import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Text from '../Text';

import { ContentText, themes, Button } from './ButtonStyle';

const ButtonComponent = ({
  children,
  type,
  textVariant,
  fontSize,
  onPress,
  disabled,
}) => {
  return (
    <ThemeProvider theme={themes[type]}>
      <Button
        disabled={disabled}
        onClick={() => {
          onPress();
        }}
        onTouchStart={() => {
          onPress();
        }}
        onTouchEnd={(e) => e.preventDefault()}
      >
        <ContentText>
          <Text
            text={children}
            variant={textVariant}
            color={themes[type].color}
            size={fontSize || themes[type].fontSize}
            uppercase={themes[type].textTransform}
          />
        </ContentText>
      </Button>
    </ThemeProvider>
  );
};

ButtonComponent.defaultProps = {
  type: 'default',
  disabled: false,
  textVariant: 'sofiaProBold',
  fontSize: '',
};

ButtonComponent.propTypes = {
  textVariant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
};

export default ButtonComponent;
