import styled from 'styled-components';

const cicleCheck = (width, height) => `
  transform: translate(${(width * 99) / 100 - height}px, -50%);
  background-color: #308841;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  display: inline-block;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;

  input {
    display: none;
  }
`;

export const Input = styled.input``;

export const Slider = styled.div`
  background-color: ${(props) => props.colorDisable};
  border-radius: 30px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  ${(props) => (props.check ? `background-color: ${props.colorActive};` : null)}

  &::before {
    content: '';
    width: ${(props) => (props.height * 90) / 100}px;
    height: ${(props) => (props.height * 90) / 100}px;
    background-color: #bababa;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    position: absolute;
    transition: all 0.4s;
    border-radius: 50%;
    ${(props) => (props.check ? cicleCheck(props.width, props.height) : null)}
  }

  p:first-child {
    position: absolute;
    top: 50%;
    left: 20%;
    font-size: 10px;
    transform: translateY(-50%);
    transition: opacity 0.2s;
    opacity: 0;
    font-weight: 600;
    ${(props) => (props.check ? 'opacity: 1;' : null)}
  }

  p:last-child {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
    font-size: 11px;
    transition: opacity 0.2s;
    color: #595959;
    font-weight: 600;
    opacity: 0;
    ${(props) => (props.check ? null : 'opacity: 1;')}
  }
`;

export default SwitchContainer;
