import React from 'react';
import PropTypes from 'prop-types';

import Image from './ImageStyle';

const Img = ({ alt, ...props }) => <Image alt={alt} {...props} />;

Img.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default Img;
