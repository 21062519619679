import styled from 'styled-components';

export const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  transform: ${(props) => (props.isOpen ? 'scale(1)' : 'scale(0)')};
`;

export const Background = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(152deg, #2f2625 10%, #000000 68%);
  opacity: 0.71;
  z-index: 1;
`;

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  border-radius: 6px;
  background-color: #ffffff;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: absolute;
`;

export const ContentImage = styled.div`
  margin: 20px 0 15px 0;
`;

export const ContentTitle = styled.div`
  margin-top: 20px;
  text-align: center;
  max-width: ${(props) => props.titleMaxWidth};
  p {
    line-height: 1.13;
    letter-spacing: -0.67px;
  }
`;

export const ContentButton = styled.div`
  min-width: 203px;
  margin-top: 35px;
  margin-bottom: 25px;
`;
