import React from 'react';

import {
  ExplotionContainer,
  ExplotionOne,
  ExplotionOT,
  ExplotionTwo,
  ExplotionThree,
  ExplotionTT,
  ExplotionFour,
  ExplotionFive,
  ExplotionSix,
  ExplotionSeven,
} from './ExplotionStyle';

const Explotion = () => {
  return (
    <ExplotionContainer>
      <ExplotionOne />
      <ExplotionOT />
      <ExplotionTwo />
      <ExplotionTT />
      <ExplotionThree />
      <ExplotionFour />
      <ExplotionFive />
      <ExplotionSix />
      <ExplotionSeven />
    </ExplotionContainer>
  );
};

export default Explotion;
