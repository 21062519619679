import styled from 'styled-components';

export const Wrapper = styled.section`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  position: absolute;
  transition: all ${(props) => props.choiceAnimationDuration}s;
  top: ${(props) => props.position.top}%;
  left: ${(props) => props.position.left}%;
  width: ${(props) => props.width}%;
  height: ${(props) => props.height}%;
`;

export default Wrapper;
