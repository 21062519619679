import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Fade from './FadeStyle';

const FadeComponent = ({ show, children, ...rest }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <Fade show={show} onAnimationEnd={onAnimationEnd} {...rest}>
        {children}
      </Fade>
    )
  );
};

FadeComponent.defaultProps = {
  show: false,
  duration: 1,
};

FadeComponent.propTypes = {
  duration: PropTypes.number,
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default FadeComponent;
