import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Fade = styled.div`
  animation-name: ${(props) => (props.show ? fadeIn : fadeOut)};
  animation-duration: ${(props) => props.duration}s;
`;

export default Fade;
