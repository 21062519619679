import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import { SwitchContainer, Label, Input, Slider } from './SwitchStyle';

const Switch = ({
  width,
  height,
  isCheck,
  onChange,
  colorActive,
  colorDisable,
}) => {
  return (
    <SwitchContainer>
      <Label htmlFor="checkbox" check={isCheck} width={width} height={height}>
        <Input type="checkbox" id="checkbox" onChange={onChange} />
        <Slider
          width={width}
          height={height}
          check={isCheck}
          colorActive={colorActive}
          colorDisable={colorDisable}
        >
          <Text text="ON" />
          <Text text="OFF" />
        </Slider>
      </Label>
    </SwitchContainer>
  );
};

Switch.defaultProps = {
  colorActive: '#66bf6b',
  colorDisable: '#e7e7e7',
  width: 62,
  height: 30,
};

Switch.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  colorActive: PropTypes.string,
  colorDisable: PropTypes.string,
  isCheck: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
