import styled from 'styled-components';

export const InputStyle = styled.input`
  width: 100%;
  height: 63px;
  background-color: white;
  border-radius: 6px;
  font-family: 'Sofia-Pro-Regular';
  border: none;
  box-shadow: none;
  text-align: center;
  font-size: 18px;
  color: black;

  &::placeholder {
    font-family: 'Sofia-Pro-Regular';
    color: #707070;
  }
  &:focus {
    outline: none;
  }
`;

export default InputStyle;
