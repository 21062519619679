import styled, { keyframes, css } from 'styled-components';

const levitation = keyframes`
  0% {
    translate: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    translate: translateY(0);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
`;

const scaleCircle = (scale) => {
  return keyframes`
    from {
      opacity: 0;
      transform: scale(0.5);
    }

    to {
      opacity: 1;
      transform: scale(${scale});
    }
  `;
};

const scaleNormal = () =>
  `
  transform: scale(1);
  opacity: 1;
`;

const scaleContent = (scale) =>
  `
  transform: scale(${scale});
  z-index: -1;
`;

const reduceContent = (scale) =>
  `
  transform: scale(${scale});
  opacity: 0.5;
`;

export const Option = styled.div`
  position: relative;
  width: ${(props) => props.width || '95px'};
  height: ${(props) => props.height || '127px'};
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${(props) => (props.selected ? null : levitation)} 3s ease-in
    infinite;
`;

export const OptionContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.7s;
  z-index: 1;
  opacity: 0;
  transform: ${(props) => `scale(${props.scaleImage})`};
  ${() => scaleNormal()};
  ${(props) => (props.selected ? scaleContent(props.scaleImage) : '')};
  ${(props) => (props.unselected ? reduceContent(props.reduceContent) : '')};
  cursor: pointer;
`;

export const OptionChecked = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -40%);
  z-index: 2;
  transition: opacity 0.2s ease-in;
  opacity: ${(props) => (props.selected ? 1 : 0)};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const OptionImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  img {
    position: relative;
    border-radius: 50%;
  }
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
      29deg,
      #5cc9ba 16%,
      rgba(215, 146, 133, 0.73) 83%
    );
    opacity: 0;
    transform: ${(props) => `scale(${props.scaleAnimation})`};
    transition: all 0.7s;
    ${(props) =>
      props.selected &&
      css`
        animation: ${scaleCircle(props.scaleAnimation)} ${props.scaleDuration}s
          ease-in forwards;
      `}
  }
`;

export const OptionText = styled.div`
  position: absolute;
  z-index: 1;
  width: 160%;
  height: 70%;
  left: 50%;
  bottom: -50%;
  transform: translate(-50%, 60%);
  text-align: center;
`;

export const OptionPercentage = styled.div`
  margin-bottom: 10%;
  z-index: 1;
`;

export default Option;
