import styled, { keyframes } from 'styled-components';

const animationExplotionOne = keyframes`
  from {
    opacity: 1;
    transform: translate(360%, -0%) rotate(-50deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(-700%,-80%) rotate(-50deg) scale(1)
  }
`;

const animationExplotionOT = keyframes`
  from {
    opacity: 1;
    transform: translate(-30%, -0%) rotate(-30deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(-600%,-120%) rotate(-30deg) scale(1);
  }
`;

const animationExplotionTwo = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -210%) scale(1);
  }
`;

export const animationExplotionTT = keyframes`
  from {
    opacity: 1;
    transform: translate(-30%, -0%) rotate(30deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(600%, -120%) rotate(30deg) scale(1)
  }
`;

const animationExplotionThree = keyframes`
  from {
    opacity: 1;
    transform: translate(-360%, -0%) rotate(50deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(700%, -80%) rotate(50deg) scale(1);
  }
`;

const animationExplotionFour = keyframes`
  from {
    opacity: 1;
    transform: translateY(-50%) rotate(90deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(900%, -50%) rotate(90deg) scale(1);
  }
`;

const animationExplotionFive = keyframes`
  from {
    opacity: 1;
    transform: translate(-360%, 0%) rotate(130deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(700%, 80%) rotate(130deg) scale(1);
  }
`;

const animationExplotionSix = keyframes`
  from {
    opacity: 1;
    transform: translate(360%, 0%) rotate(-130deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(-700%, 80%) rotate(-130deg) scale(1);
  }
`;

const animationExplotionSeven = keyframes`
  from {
    opacity: 1;
    transform: translateY(-50%) rotate(270deg) scale(0.5);
  }
  to {
    opacity: 0;
    transform: translate(-900%, -50%) rotate(270deg) scale(1);
  }
`;

export const ExplotionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const ExplotionElement = styled.div`
  border-radius: 5px 5px 0;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 10%,
    #ffffff 90%
  );
`;

export const ExplotionOne = styled(ExplotionElement)`
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 45px;
  transform: translate(360%, -0%) rotate(-50deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionOne};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionOT = styled(ExplotionElement)`
  position: absolute;
  top: 0;
  left: 30%;
  width: 5px;
  height: 30px;
  transform: translate(-30%, -0%) rotate(-30deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionOT};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionTwo = styled(ExplotionElement)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 5px;
  height: 30px;
  transform: translate(-50%, 0) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionTwo};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionTT = styled(ExplotionElement)`
  position: absolute;
  top: 0;
  right: 30%;
  width: 5px;
  height: 30px;
  transform: translate(-30%, -0%) rotate(30deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionTT};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionThree = styled(ExplotionElement)`
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 45px;
  transform: translate(-360%, -0%) rotate(50deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionThree};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionFour = styled(ExplotionElement)`
  position: absolute;
  top: 50%;
  right: 0;
  width: 5px;
  height: 30px;
  transform: translateY(-50%) rotate(90deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionFour};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionFive = styled(ExplotionElement)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 5px;
  height: 45px;
  transform: translate(-360%, 0%) rotate(130deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionFive};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionSix = styled(ExplotionElement)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 45px;
  transform: translate(360%, 0%) rotate(-130deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionSix};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

export const ExplotionSeven = styled(ExplotionElement)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 30px;
  transform: translateY(-50%) rotate(90deg) scale(0);
  animation-delay: 1s;
  animation-name: ${animationExplotionSeven};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;
