import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import SingleOption from '../SingleOption';

import currentConfig from './ObjectBalls';

import { Wrapper, Circle } from './WrapperOptionsStyle';

const WrapperOptions = ({
  type,
  balls,
  width,
  height,
  onChange,
  labelOptionSize,
  parentWidth,
  parentHeight,
  scaleAnimation,
  scaleContent,
  reduceContent,
  shouldAnimateWinner,
  choiceAnimationDuration,
  scaleAnimationDuration,
}) => {
  const [selected, setSelected] = useState(null);
  const currentBalls = currentConfig(selected, width)[balls.length];
  const typeOption = type === 'option';
  return (
    <Wrapper width={parentWidth} height={parentHeight}>
      {balls.length > 1 ? (
        currentBalls.map((item, index) => {
          const currentPosition = currentBalls[index];
          return (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              width={width}
              height={height}
              position={currentPosition}
              onClick={() => {
                if (typeOption) {
                  setSelected(index + 1);
                  onChange(balls[index]);
                }
              }}
              onTouchStart={() => {
                if (typeOption) {
                  setSelected(index + 1);
                  onChange(balls[index]);
                }
              }}
              onTouchEnd={(e) => e.preventDefault()}
              choiceAnimationDuration={choiceAnimationDuration}
            >
              <SingleOption
                width={`${(parentWidth * width) / 100}px`}
                height={`${(parentHeight * height) / 100}px`}
                image={balls[index].image}
                winnerImage={balls[index].winner_image}
                iconCheck={balls[index].iconCheck}
                label={balls[index].label}
                labelSize={labelOptionSize}
                selected={selected - 1 === index}
                unselected={selected && selected - 1 !== index}
                percentage={balls[index].percentage}
                isWinner={balls[index].current_winner}
                shouldAnimateWinner={shouldAnimateWinner}
                type={type}
                scaleAnimation={scaleAnimation}
                scaleContent={scaleContent}
                reduceContent={reduceContent}
                scaleDuration={scaleAnimationDuration}
              />
            </Circle>
          );
        })
      ) : (
        <p>Loading...</p>
      )}
    </Wrapper>
  );
};

WrapperOptions.defaultProps = {
  balls: [
    {
      uuid: 'xxxx-xxxx-xxxx-xxy',
      image:
        'https://i7.pngguru.com/preview/522/190/389/loudspeaker-symbol-icon-speaker-icon.jpg',
      winner_image: 'newstand.com/media/background.png',
      iconCheck:
        'https://s3.amazonaws.com/staging-poll.newstand.com/static/media/check.8aecfc21.svg',

      label: 'Option a',
      percentage: '55',
    },
    {
      uuid: 'xxxx-xxxx-xxxx-xxy',
      image:
        'https://i7.pngguru.com/preview/522/190/389/loudspeaker-symbol-icon-speaker-icon.jpg',
      winner_image: 'newstand.com/media/background.png',
      iconCheck:
        'https://s3.amazonaws.com/staging-poll.newstand.com/static/media/check.8aecfc21.svg',

      label: 'Option b',
      percentage: '55',
    },
    {
      uuid: 'xxxx-xxxx-xxxx-xxy',
      image:
        'https://i7.pngguru.com/preview/522/190/389/loudspeaker-symbol-icon-speaker-icon.jpg',
      winner_image: 'newstand.com/media/background.png',
      iconCheck:
        'https://s3.amazonaws.com/staging-poll.newstand.com/static/media/check.8aecfc21.svg',

      label: 'Option c',
      percentage: '55',
    },
    {
      uuid: 'xxxx-xxxx-xxxx-xxy',
      image:
        'https://i7.pngguru.com/preview/522/190/389/loudspeaker-symbol-icon-speaker-icon.jpg',
      winner_image: 'newstand.com/media/background.png',
      iconCheck:
        'https://s3.amazonaws.com/staging-poll.newstand.com/static/media/check.8aecfc21.svg',

      label: 'Option d',
      percentage: '55',
    },
  ],
  width: 20,
  height: 20,
  scaleAnimation: 7,
  scaleContent: 1.3,
  reduceContent: 0.7,
  label: 'App',
  labelOptionSize: '1.25rem',
  parentWidth: 500,
  parentHeight: 500,
  shouldAnimateWinner: false,
  type: 'option',
  onChange: () => {},
  choiceAnimationDuration: 0.2,
  scaleAnimationDuration: 0.2,
};

WrapperOptions.propTypes = {
  type: PropTypes.string,
  shouldAnimateWinner: PropTypes.bool,
  balls: PropTypes.arrayOf,
  width: PropTypes.number,
  height: PropTypes.number,
  scaleAnimation: PropTypes.number,
  scaleContent: PropTypes.number,
  reduceContent: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  labelOptionSize: PropTypes.string,
  parentWidth: PropTypes.number,
  parentHeight: PropTypes.number,
  choiceAnimationDuration: PropTypes.number,
  scaleAnimationDuration: PropTypes.number,
};

export default WrapperOptions;
