import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './TextStyle';

const TextComponent = ({ text, variant, ...props }) => (
  <Text variant={variant} {...props}>
    {text}
  </Text>
);

TextComponent.defaultProps = {
  variant: 'default',
};

TextComponent.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default TextComponent;
