import styled from 'styled-components';

// Assets
import background from '../../assets/background.png';

export const ContentText = styled.div`
  margin-top: 4px;
`;

export const themes = {
  default: {
    background: 'transparent',
    backgroundImage: `url(${background})`,
    width: '100%',
    height: '59px',
    border: 'none',
    color: '#2ea1b5',
    fontSize: '21px',
    fontWeight: '900',
    letterSpacing: ' 0.4px',
    textTransform: 'uppercase',
  },
  primary: {
    background: 'transparent',
    width: '100%',
    height: '43px',
    borderRadius: '6px',
    border: 'solid 2px #ffffff',
    color: '#ffffff',
    fontSize: '17px',
    fontWeight: '900',
    letterSpacing: ' 0.32px',
    textTransform: 'uppercase',
  },
  secundary: {
    background: '#533af0',
    width: '100%',
    height: '50px',
    borderRadius: '6px',
    color: '#ffffff',
    fontSize: '17px',
    fontWeight: '900',
    letterSpacing: ' 0.32px',
    textTransform: 'uppercase',
    border: 'none',
  },
  tertiary: {
    background: '#824bf6',
    width: '100%',
    height: '43px',
    borderRadius: '6px',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: '600',
    letterSpacing: ' 0.28px',
    textTransform: 'uppercase',
    border: 'none',
  },
};

export const Button = styled.button`
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.height};
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.background};
  background-image: ${(props) => props.theme.backgroundImage};
  border-radius: ${(props) => props.theme.borderRadius};
  border: ${(props) => props.theme.border};
  font-size: ${(props) => props.theme.fontSize};
  font-weight: ${(props) => props.theme.fontWeight};
  letter-spacing: ${(props) => props.theme.letterSpacing};
  outline: none;
  cursor: pointer;
  text-transform: ${(props) => props.theme.textTransform};
  background-size: cover;
  background-repeat: no-repeat;
  opacity: ${(props) => (props.disabled ? '.8' : '1')};
`;
