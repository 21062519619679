const currentConfig = (selected, sizeBall) => {
  const BASE = 100;
  const configForTwoElements = {
    first: {
      top: {
        default: BASE / 2 - sizeBall / 2,
      },
      left: {
        default: 10,
      },
    },
    second: {
      top: {
        default: BASE / 2 - sizeBall / 2,
      },
      left: {
        default: BASE - sizeBall - 10,
      },
    },
  };
  const configForThreeElements = {
    first: {
      top: {
        1: BASE / 2 - sizeBall / 2,
        2: 10,
        3: BASE - sizeBall - 10,
        default: 10,
      },
      left: {
        1: 10,
        2: BASE - sizeBall * 2,
        3: BASE - sizeBall * 2,
        default: BASE - (60 + sizeBall),
      },
    },
    second: {
      top: {
        1: 10,
        2: BASE / 2 - sizeBall / 2,
        3: 10,
        default: BASE / 2 - sizeBall / 2,
      },
      left: {
        1: BASE - sizeBall * 2,
        2: 10,
        3: BASE - sizeBall * 2,
        default: 60,
      },
    },
    three: {
      top: {
        1: BASE - sizeBall - 10,
        2: BASE - sizeBall - 10,
        3: BASE / 2 - sizeBall / 2,
        default: BASE - sizeBall - 10,
      },
      left: {
        1: BASE - sizeBall * 2,
        2: BASE - sizeBall * 2,
        3: 10,
        default: BASE - (60 + sizeBall),
      },
    },
  };
  const configForFourElements = {
    first: {
      top: {
        1: BASE / 2 - sizeBall / 2,
        2: 10,
        3: BASE - sizeBall - 10,
        4: BASE - sizeBall - 10,
        default: 15,
      },
      left: {
        1: 10,
        2: BASE - sizeBall * 1.3,
        3: BASE - sizeBall * 1.3,
        4: BASE - sizeBall * 1.3,
        default: 20,
      },
    },
    second: {
      top: {
        1: 10,
        2: BASE / 2 - sizeBall / 2,
        3: 10,
        4: 10,
        default: 15,
      },
      left: {
        1: BASE - sizeBall * 1.3,
        2: 10,
        3: BASE - sizeBall * 1.3,
        4: BASE - sizeBall * 1.3,
        default: BASE - (20 + sizeBall),
      },
    },
    three: {
      top: {
        1: BASE / 2 - sizeBall / 2,
        2: BASE / 2 - sizeBall / 2,
        3: BASE / 2 - sizeBall / 2,
        4: BASE / 2 - sizeBall / 2,
        default: BASE - sizeBall - 15,
      },
      left: {
        1: BASE - sizeBall * 2,
        2: BASE - sizeBall * 2,
        3: 10,
        4: BASE - sizeBall * 2,
        default: 20,
      },
    },
    four: {
      top: {
        1: BASE - sizeBall - 10,
        2: BASE - sizeBall - 10,
        3: BASE / 2 - sizeBall / 2,
        4: BASE / 2 - sizeBall / 2,
        default: BASE - sizeBall - 15,
      },
      left: {
        1: BASE - sizeBall * 1.3,
        2: BASE - sizeBall * 1.3,
        3: BASE - sizeBall * 2,
        4: 10,
        default: BASE - (20 + sizeBall),
      },
    },
  };
  return {
    2: [
      {
        top:
          configForTwoElements.first.top[selected] ||
          configForTwoElements.first.top.default,
        left:
          configForTwoElements.first.left[selected] ||
          configForTwoElements.first.left.default,
      },
      {
        top:
          configForTwoElements.second.top[selected] ||
          configForTwoElements.second.top.default,
        left:
          configForTwoElements.second.left[selected] ||
          configForTwoElements.second.left.default,
      },
    ],
    3: [
      {
        top:
          configForThreeElements.first.top[selected] ||
          configForThreeElements.first.top.default,
        left:
          configForThreeElements.first.left[selected] ||
          configForThreeElements.first.left.default,
      },
      {
        top:
          configForThreeElements.second.top[selected] ||
          configForThreeElements.second.top.default,
        left:
          configForThreeElements.second.left[selected] ||
          configForThreeElements.second.left.default,
      },
      {
        top:
          configForThreeElements.three.top[selected] ||
          configForThreeElements.three.top.default,
        left:
          configForThreeElements.three.left[selected] ||
          configForThreeElements.three.left.default,
      },
    ],
    4: [
      {
        top:
          configForFourElements.first.top[selected] ||
          configForFourElements.first.top.default,
        left:
          configForFourElements.first.left[selected] ||
          configForFourElements.first.left.default,
      },
      {
        top:
          configForFourElements.second.top[selected] ||
          configForFourElements.second.top.default,
        left:
          configForFourElements.second.left[selected] ||
          configForFourElements.second.left.default,
      },
      {
        top:
          configForFourElements.three.top[selected] ||
          configForFourElements.three.top.default,
        left:
          configForFourElements.three.left[selected] ||
          configForFourElements.three.left.default,
      },
      {
        top:
          configForFourElements.four.top[selected] ||
          configForFourElements.four.top.default,
        left:
          configForFourElements.four.left[selected] ||
          configForFourElements.four.left.default,
      },
    ],
  };
};

export default currentConfig;
